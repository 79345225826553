<template>
  <van-action-sheet v-model:show="showInvite" class="sheet" :round="false">
    <div class="share-wrapper">
      <div class="header">
        <img src="@/imgs/comm/pic_tc_zs.png" class="img-zs" />

        <span class="type">分享</span>
      </div>
      <div class="share-content">
        <div class="share-item" @click="qrcodeDom">
          <img :src="icon_szcp_yqhb" alt="" />
          <p>生成邀请海报</p>
        </div>
        <div class="share-item" @click="openCopytextPop">
          <img :src="icon_szcp_yqwb" alt="" />
          <p>生成邀请文本</p>
        </div>
        <div class="share-item" @click="copyCode">
          <div class="my">我的邀请码</div>
          <div class="code">{{ isLogin ? myInviteCode : '--' }}</div>
          <div class="copy">复制</div>
        </div>
      </div>
      <div class="btn" @click="close">取消</div>
    </div>
  </van-action-sheet>
  <div class="" id="invite-activity">
    <!-- 复制文本弹窗 -->
    <van-popup class="vantPop" v-model:show="copytextPop">
      <div class="popup">
        <div class="copytextPop">
          <div class="title">文本已复制</div>
          <div class="tip">打开第三方软件粘贴给好友</div>
          <div class="copyContent">
            <div>{{ shareText }}</div>

            <div style="color: #3cdbe8; font-weight: 500" @click="copyText">复制文本</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 海报 -->
    <div style="position: fixed; bottom: 200%; font-size: 0">
      <div id="testPost">
        <img class="posterBg" :src="posterBg + '?v=06282'" />
        <!-- <div id="qrcode" class="qrcodeImg"></div> -->
        <img class="qrcodeImg" :src="qrcodeImg" alt="" srcset="" />
      </div>
    </div>

    <van-popup class="vantPop" v-model:show="posterPop">
      <div class="poster">
        <!-- <van-image width="100" height="100" :src="posterImg" /> -->
        <img style="pointer-events: auto !important" :src="posterImg" alt="" srcset="" />

        <div v-if="posterImg" class="saveTip">长按图片保存</div>
      </div>
    </van-popup>

    <PageLoading :show="pageLoading" />
  </div>
</template>

<script>
import { reactive, toRefs, inject, onMounted } from 'vue';
import { nftUtils } from '@/utils';
import { useRouter } from 'vue-router';
import useClipboard from 'vue-clipboard3';
import PageLoading from '@/components/loading/PageLoading.vue';
import { useStore } from 'vuex';
import QRCode from 'qrcode';
import domtoimage from 'dom-to-image';
import upload from '@/utils/upload.js';
export default {
  emits: ['close'],
  components: {
    PageLoading
  },
  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const store = useStore();
    const { toClipboard } = useClipboard();
    const state = reactive({
      isLogin: nftUtils.isLogin(),
      copytextPop: false, // 复制文本弹窗
      shareText: '',
      showInvite: false,
      myInviteCode: 'F3YD',
      pageLoading: false,
      posterBg: require('../imgs/poster/posterUser.jpg'),
      qrcodeImg: null, // 二维码图片
      posterPop: false, // 弹窗显示
      posterImg: '' // 海报图片
    });
    onMounted(async () => {
      if (state.isLogin) {
        await store.dispatch('refreshLoginInfo');
        console.log(store);
        if (store.state.loginInfo) {
          state.myInviteCode = store.state.loginInfo.my_code;
        }
      }
    });
    const close = () => {
      // ctx.emit('close');
      state.showInvite = false;
    };
    const showInviteMeth = () => {
      state.showInvite = true;
    };
    // 生成海报
    const qrcodeDom = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      state.showInvite = false;
      state.pageLoading = true;
      nftUtils.setcnzzRecordByBtn('生成专属邀请海报');
      let url = window.location.origin;
      // if (!window.location.origin) {
      //   url =
      //     window.location.protocol +
      //     '//' +
      //     window.location.hostname +
      //     (window.location.port ? ':' + window.location.port : '');
      // } else {
      //   url = window.location.origin;
      // }
      if (url.indexOf('mh51') > -1) {
        url = 'https://collect.yx03.com';
        // url = url.replace('https://collect.mh51.com/', 'https://collect.yx03.com/');
      }
      if (store.state.commonPosterUrl) {
        // 上传过后不用生成和上传了
        havePosterUrlToPoster();
      } else {
        // 没有上传时重新上传
        await store.dispatch('refreshLoginInfo');
        QRCode.toDataURL(
          url + '/login?invite_user_id=' + state.myInviteCode,
          { margin: 0 },
          (err, url) => {
            if (err) throw err;
            state.qrcodeImg = url;
            uploadDomToPoster(nftUtils.isIOS());
          }
        );
      }
    };
    // 直接转为Blob生成并上传的oss，iOS特殊处理，需要执行两次toBlob
    const uploadDomToPoster = isIos => {
      if (!store.state.commonPosterUrl) {
        if (isIos) {
          console.log('store.state.commonPosterUrl', store.state.commonPosterUrl);
          domtoimage.toBlob(document.getElementById('testPost'), { quality: 1 }).then(dataUrl => {
            updateDomToPosterOnece();
          });
        } else {
          updateDomToPosterOnece();
        }
      } else {
        havePosterUrlToPoster();
      }
    };
    // 直接转为toBlob，首次进入时两次生成并上传oss显示，第二次及以后，只需一次生成并上传oss显示
    const updateDomToPosterOnece = () => {
      domtoimage.toBlob(document.getElementById('testPost'), { quality: 1 }).then(dataUrl => {
        if (nftUtils.isSupprotNftShare()) {
          upload.uploadHandle64Common(dataUrl, function (link) {
            nftUtils.nftSharePic(link);
            state.pageLoading = false;
            store.state.commonPosterUrl = link;
          });
        } else {
          upload.uploadHandle64Common(dataUrl, function (link) {
            console.log(link);
            state.posterImg = link;
            state.posterPop = true;
            state.pageLoading = false;
            store.state.commonPosterUrl = link;
            console.log(link);
          });
        }
      });
    };

    const havePosterUrlToPoster = () => {
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftSharePic(store.state.commonPosterUrl);
        state.pageLoading = false;
      } else {
        state.posterImg = store.state.commonPosterUrl;
        state.posterPop = true;
        state.pageLoading = false;
      }
    };
    const openCopytextPop = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      const shareUrl = window.location.origin + '/login?invite_user_id=' + state.myInviteCode;
      state.shareText = '注册看漫数藏并实名认证，超多空投福利免费领。用浏览器打开：' + shareUrl;
      console.log(nftUtils.isSupprotNftShare());
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftShareText(state.shareText);
      } else {
        state.copytextPop = true;
        try {
          await toClipboard(state.shareText);
        } catch (e) {
          console.log(e);
        }
      }
    };
    // 复制code码
    const copyCode = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      nftUtils.setcnzzRecordByBtn('复制邀请码');
      try {
        await toClipboard(state.myInviteCode);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    // 复制邀请链接
    const copyText = async () => {
      nftUtils.setcnzzRecordByBtn('复制文本');
      try {
        await toClipboard(state.shareText);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      ...toRefs(state),
      close,
      qrcodeDom,
      openCopytextPop,
      copyCode,
      copyText,
      showInviteMeth,
      icon_szcp_yqhb: require('@/imgs/icon/icon_szcp_yqhb.png'),
      icon_szcp_yqwb: require('@/imgs/icon/icon_szcp_yqwb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 30px 16px;
    line-height: 26px;
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.copytextPop {
  padding: 24px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .tip {
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.7);
  }

  .copyContent {
    margin-top: 16px;
    background: #1c172a;
    border-radius: 4px 4px 4px 4px;
    padding: 12px;

    line-height: 22px;
  }
}
.btn {
  height: 44px;
  font-weight: 500;
  // background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  border-radius: 4px 4px 4px 4px;
  margin: 0 20px;
}
.share-content {
  margin: 24px 20px;
  display: flex;
  justify-content: space-between;

  .share-item {
    width: 105px;
    height: 105px;
    background: #494359;
    border-radius: 8px;
    text-align: center;
    font-size: 0px;
    img {
      width: 36px;
      height: 36px;
      margin: 24px 0 12px 0;
    }
    p {
      margin: 0px;
      font-size: 12px;
    }
    .my {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 18px;
    }
    .code {
      color: #3cdbe8;
      font-size: 18px;
      font-weight: 600;
      margin: 2px 0 8px 0;
    }
    .copy {
      font-size: 12px;
      color: #3cdbe8;
      font-weight: 400;
      width: 56px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px 16px 16px 16px;
      margin: 0 auto;
      border: 1px solid #3cdbe8;
    }
  }
}
.block2 {
  height: 28px;
  background-color: $bc;
}
.share-wrapper {
  // position: fixed;
  // top: 0;
  padding-bottom: 36px;
  background-color: $bc;
  color: #fff;
}
.header {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;

  padding-top: 3px;

  .img-zs {
    height: 16px;
  }
  .icon_tc_gb {
    margin-left: 300px;
    width: 16px;
    height: 16px;
  }
  .img-title {
    height: 16px;
  }
  .type {
    // line-height: 16px;
    margin: 16px 0 24px 20px;
    font-size: 20px;
    font-weight: 500;
  }
  span {
    font-size: 20px;
    color: $fc;
    margin-top: -20px;
  }
}

#testPost {
  position: relative;
  display: inline-block;
  .posterBg {
    width: 616px;
    height: 1044px;
  }

  .qrcodeImg {
    width: 228px;
    height: 228px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // top: 352px;
    border: 6px solid;
    border-radius: 2px 2px 2px 2px;
    border-image: linear-gradient(180deg, rgba(255, 233, 203, 1), rgba(141, 129, 118, 1)) 3 3;
  }
}

.poster {
  width: 308px;

  .saveTip {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }

  img {
    width: 100%;
  }
}
</style>
<style lang="scss">
#invite-activity {
  .vantPop {
    background: transparent !important;
  }

  .van-field__label,
  .van-field__value,
  .van-field__control {
    color: white;
  }

  .shareBtn,
  .posterBtn {
    text-align: center;
    width: 105px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 36px;
    color: #1c172a;
  }

  .qrcodeImg {
    top: 292px;
  }
}
</style>
