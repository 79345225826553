<!--
 * 我的页面
-->

<template>
  <div>
    <div class="page-bg"></div>
    <div class="user-box">
      <div class="user-header">
        <van-image
          class="image"
          @click="clickAvatar"
          width="2.08rem"
          height="2.08rem"
          fit="contain"
          :src="isLogin ? pic_avatar_login : pic_avatar"
        />

        <div class="user-desc">
          <span @click="clickAvatar">{{ isLogin ? user.user_name : '未登录' }}</span>
          <span v-if="isLogin" class="user-id"
            >id:{{ user.my_id }}<van-icon :name="icon_copy" class="icon_copy" @click="copyMobile" />
          </span>
        </div>

        <!-- <van-icon class="user-set" size="0.64rem" :name="icon_sz" @click="goTo('/set', '设置')" /> -->
      </div>

      <div class="user-flex">
        <!-- <div @click="goTo('/order', '订单')">
          <div>
            <img :src="icon_dd" />
          </div>
          <div>订单</div>
        </div> -->

        <div @click="goTo('/collections', '我的藏品')">
          <div>
            <img :src="icon_cp" />
          </div>
          <div>藏品</div>
        </div>
        <div @click="goTo('/wishWell', '许愿池')" class="positDot">
          <div>
            <img :src="icon_wish" />
          </div>
          <div>许愿池</div>
          <div class="dot" v-if="wishPoint == 1"></div>
        </div>
        <div @click="goTo('/myPoint', '积分')">
          <div>
            <img :src="icon_point" />
          </div>
          <div>积分</div>
        </div>

        <div @click="goTo('/giftmanage', '赠予管理')" class="positDot">
          <div>
            <img :src="icon_zy" />
          </div>
          <div>转赠</div>
          <div class="dot" v-if="sendPoint == 1"></div>
        </div>
      </div>

      <ul class="user-list">
        <!-- <li @click="goTo('/collections', '我的藏品')">
          <img class="icon_icon" :src="icon_cp" />
          <span>我的藏品</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
        <li @click="goTo('/order', '订单')">
          <img class="icon_icon" :src="icon_dd" />
          <span>订单</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
        <li @click="goTo('/giftmanage', '赠予管理')">
          <img class="icon_icon" :src="icon_zy" />
          <span>赠予管理</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li> -->
        <li @click="toHc">
          <img class="icon_icon" :src="icon_mine_hc" />
          <span>合成</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
        <li @click="goTo('/order', '订单')">
          <img class="icon_icon" :src="icon_dd" />
          <span>订单</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
        <!-- <li @click="goTo('/invite/collectActivity', '学院收藏值活动')">
          <img class="icon_icon" :src="icon_mine_xysc" />
          <span>学院收藏值活动</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li> -->
        <li @click="toQb">
          <img class="icon_icon" :src="icon_qb" />
          <span>钱包</span>
          <span class="authentication-text"
            >可用余额¥{{ availableBal / 100 }} 许愿冻结¥{{ frozenBal / 100 }}</span
          >
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
        <li @click="toHb">
          <img class="icon_icon" :src="icon_hb" />
          <span>我的红包</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>

        <!-- <li @click="toPrepaid">
          <img class="icon_icon" :src="icon_tq" />
          <span>我的特权</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li> -->

        <!-- <li @click="toInvite">
          <img class="icon_icon" :src="icon_invite" />
          <span>国庆邀请活动 </span>
          <span class="authentication-text">豪礼放送中</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li> -->
        <li @click="toInviteAlways">
          <img class="icon_icon" :src="icon_mine_yqhy" />
          <span>邀请好友</span>

          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>

        <!-- <li @click="goTo(isVerify ? '/authsuccess' : '/authentication', '实名认证')">
          <img class="icon_icon" :src="icon_sm" />
          <span>实名认证</span>
          <span class="authentication-text">{{ isVerify ? '已实名' : '未实名' }}</span
          ><van-icon :name="icon_arrow" class="icon_arrow" />
        </li> -->

        <li @click="goTo('/set', '设置')">
          <img class="icon_icon" :src="icon_sz" />
          <span>设置</span>
          <van-icon :name="icon_arrow" class="icon_arrow" />
        </li>
      </ul>

      <invitePoup ref="dialogShow" @close="showInvite = false" />
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, ref, inject } from 'vue';
import { gethotdot, sandpayCloud, getwhiteuser, sandpay_balance } from '@/service/user';
import navBar from '@/components/NavBar';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { nftUtils } from '@/utils';
import invitePoup from '@/components/IvitePoup';
import useClipboard from 'vue-clipboard3';

export default {
  components: {
    navBar,
    invitePoup
  },
  setup() {
    const { toClipboard } = useClipboard();
    const toastFn = inject('$toast');
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      user: {},
      isLogin: false,
      isVerify: false, //是否认证
      showInvite: false, //邀请好友弹窗
      sendPoint: 0,
      sandpayAccount: 0,
      wishPoint: false,
      wishWhite: false,

      availableBal: 0,
      frozenBal: 0,
      payBalanceFlag: false
    });

    onMounted(async () => {
      state.isLogin = nftUtils.isLogin();
      if (state.isLogin) {
        state.user = store.state.loginInfo;
        state.isVerify = !!state.user.id_verify;
        await store.dispatch('refreshLoginInfo');
        state.user = store.state.loginInfo;
        state.isVerify = !!state.user.id_verify;
        state.sandpayAccount = store.state.loginInfo.sandpay_cloud_account;
        // console.log('sandpay_cloud_account', store.state.loginInfo.sandpay_cloud_account);
        await store.dispatch('gethotdot');
        state.sendPoint = store.state.sendPoint;

        await store.dispatch('getisshowredmark');
        state.wishPoint = store.state.wishPoint;

        // getWhite();
        payBalance();
      }
    });

    const payBalance = () => {
      sandpay_balance({}).then(res => {
        if (res.status == 0) {
          state.payBalanceFlag = true;
          state.availableBal = res.data.availableBal;
          state.frozenBal = res.data.frozenBal;
        }
      });
    };

    const goTo = (r, title) => {
      nftUtils.setcnzzRecordByBtn(title);
      router.push({ path: r });
    };

    const clickAvatar = () => {
      nftUtils.setcnzzRecordByBtn('头像点击');
      if (!state.isLogin) {
        goTo('/login');
      }
    };

    const toInvite = () => {
      nftUtils.setcnzzRecordByBtn('邀请有奖');
      // router.push({ path: '/inviteActivity' });
      router.push({ path: '/invite/invite6' });
    };

    const dialogShow = ref('dialogShow');
    const toInviteAlways = () => {
      // console.log('表单的$ref', dialogShow);
      nftUtils.setcnzzRecordByBtn('邀请好友');
      dialogShow.value.showInviteMeth();
      // router.push({ path: '/invite/invitealways' });
    };
    const toPrepaid = () => {
      nftUtils.setcnzzRecordByBtn('我的特权');
      router.push({ path: '/privilege' });
    };
    const toHb = () => {
      nftUtils.setcnzzRecordByBtn('我的红包');
      router.push({ path: '/myHb' });
    };

    const toHc = () => {
      nftUtils.setcnzzRecordByBtn('合成列表');
      router.push({ path: '/compoundList' });
    };

    const toQb = () => {
      nftUtils.setcnzzRecordByBtn('钱包');
      if (!state.isLogin) {
        goTo('/login');
        return;
      }
      sandpayCloud({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          window.location.href = res.data.url;
        }
      });

      // router.push({ path: '/myHb' });
    };

    const copyMobile = async () => {
      try {
        await toClipboard(state.user.my_id);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    const getWhite = () => {
      getwhiteuser({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          state.wishWhite = res.data;
        }
      });
    };

    return {
      ...toRefs(state),
      goTo,
      clickAvatar,
      toInvite,
      toPrepaid,
      toInviteAlways,
      toHb,
      copyMobile,
      getWhite,
      dialogShow,
      icon_arrow: require('../imgs/icon/icon_mine_jt.png'),
      icon_cp: require('../imgs/icon/icon_mine_cp.png'),
      icon_point: require('../imgs/icon/icon_point.png'),
      icon_sm: require('../imgs/icon/icon_mine_sm.png'),
      icon_zy: require('../imgs/icon/icon_mine_zy.png'),
      icon_dd: require('../imgs/icon/icon_mine_dd.png'),
      icon_sz: require('../imgs/icon/icon_mine_sz.png'),
      icon_invite: require('../imgs/icon/icon_mine_invite.png'),
      pic_avatar: require('../imgs/comm/pic_avatar.png'),
      pic_avatar_login: require('../imgs/comm/pic_avatar_login.png'),
      icon_tq: require('../imgs/icon/icon_mine_tq.png'),
      icon_mine_yqhy: require('../imgs/icon/icon_mine_yqhy.png'),
      icon_hb: require('../imgs/icon/icon_hb.png'),
      icon_copy: require('../imgs/icon/icon_copyblue.png'),
      icon_qb: require('../imgs/icon/icon_mine_qb.png'),
      icon_wish: require('../imgs/icon/icon_mine_wish.png'),
      icon_mine_xysc: require('../imgs/icon/icon_mine_xysc.png'),
      icon_mine_hc: require('../imgs/icon/icon_mine_hc.png'),
      toQb,
      toHc
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.positDot {
  position: relative;
  .dot {
    width: 6px;
    height: 6px;
    background: #e63f3f;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    right: 28px;
    top: 0px;
  }
}

.user-box {
  position: relative;
  padding-bottom: 80px;
  width: 100%;
  .user-header {
    display: flex;
    flex-direction: row;
    padding-top: 33px;
    padding-bottom: 19px;
    padding-left: 21px;
    .image {
      width: 78px;
      height: 78px;
    }

    .user-desc {
      font-size: 24px;
      margin-left: 10px;
      margin-top: 14px;
      display: flex;
      flex-direction: column;

      .user-id {
        display: flex;
        align-items: center;
        font-size: 12px;
        opacity: 0.8;
        margin-top: 4px;
      }

      .icon_copy {
        width: 16px;
        padding: 0 4px;
        // height: 16px;
      }
    }
    .user-set {
      position: absolute;
      right: 16px;
      top: 46px;
      width: 24px;
      height: 24px;
    }
  }
  .user-list {
    padding: 0 16px;
    margin-top: 0px;
    li {
      height: 56px;
      line-height: 56px;
      display: flex;
      margin-bottom: 12px;
      font-size: 14px;
      background-color: $primaryc;
      border-radius: 5px;
      padding: 0 11px;
      align-items: center;
      span {
        width: 90%;
      }
      .icon_arrow {
        width: 16px;
        height: 16px;
      }
      .icon_icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .authentication-text {
        white-space: nowrap;
        text-align: right;
        margin-right: 10px;
        font-size: 14px;
        opacity: 0.8;
      }
    }
  }

  .user-flex {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 24px;
    text-align: center;

    > div {
      width: 33.3%;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
